import React, { useEffect, createRef } from 'react'
import { interpolateViridis } from 'd3-scale-chromatic'
import Chart from 'chart.js/auto'

export default function HomeDashboardChart({ user }) {

  const chartRef = createRef()
  let chart = null

  const inactiveEmissionsEstimates = {
    scope_1: [130, 150, 160, 280, 270, 250, 180, 130, 160, 210, 240, 230],
    scope_2: [40, 30, 60, 90, 100, 110, 100, 70, 60, 50, 40, 30],
    scope_3: [470, 460, 240, 770, 720, 800, 840, 880, 770, 720, 660, 620]
  }

  const generateData = (user) => {
    function getLastTwelveMonths() {
      const months = [];
      const now = new Date();

      for (let i = 0; i < 12; i++) {
        const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
        const monthName = month.toLocaleString('default', { month: 'long' });
        const year = month.getFullYear();
        months.push(`${monthName} ${year}`);
      }

      return months.reverse();
    }

    const estimates = user.emissions_estimates || inactiveEmissionsEstimates

    return {
      labels: getLastTwelveMonths(),
      datasets: [
      {
        label: 'Scope 3',
        data: estimates['scope_3'],
        backgroundColor: '#E45400',
      },
      {
        label: 'Scope 2',
        data: estimates['scope_2'],
        backgroundColor: '#FDCA37',
      },
      {
        label: 'Scope 1',
        data: estimates['scope_1'],
        backgroundColor: '#43C1F0',
      },
    ]
    }
  }

  const config =  () => ({
    type: 'bar',
    data: generateData(user),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          reverse: true
        },
        tooltip:{
          mode: 'index',
          enabled: true,
          itemSort: function(a, b) {
            return b.datasetIndex - a.datasetIndex
          },
          callbacks: {
          label(tooltipItems) {
            return `${tooltipItems.formattedValue} MTCO2e`
          }
        }
        }
      },

      scales:{
        x:{
          stacked: true,
          grid: {
            display: false
          }
        },
        y:{
          stacked: true,
          grid:{
            display: true,
            color: '#ABABB5'
          },
          title: {
            display: true,
            text: 'MTCO2e', 
          }
        }
      }
    }
  })

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [user])  

  return (
    <canvas ref={chartRef} />
  )
}
