import React from 'react'
import ValuesSection from "./ValuesSection";
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom, centsFieldDom, textAreaFieldDom} from './valueFieldTypes'

export default function PolicyValues(props) {

  return (
    <>
      <ValuesSection name="policy-input">
        
        <ul>
            <li>
              <div>
                In scope equipment/areas:{" "}
              </div>
             
              {textAreaFieldDom("in_scope_equipment_or_areas", props)}
          </li>
        </ul>
        
        <ul>
            <li>
              <div>
                Roles and responsibilities:{" "}
              </div>
             
              {textAreaFieldDom("roles_and_responsbilities", props)}
          </li>
        </ul>
        
        <ul>
            <li>
              <div>
                Other details (optional):{" "}
              </div>
             
              {textAreaFieldDom("other_details", props)}
          </li>
        </ul>
      </ValuesSection>
    </>
  );
}