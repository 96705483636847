import React, { useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import classnames from 'classnames'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '../Forms/IconButton'
import LabeledField from '../Forms/LabeledField'
import AddIcon from '@mui/icons-material/Add'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Switch } from "@mui/material"
import axios from 'axios'

import Modal from '../Containers/Modal'

export default function UserTable(props) {

  const defaultErrors = {
    first_name: "",
    last_name: "",
    email: ""
  }

  const defaultInvite = {
    name: "",
    email: "",
    measure: true,
    carbon_footprint: true,
    payment: true
  }

  const defaultPermissions = {
    measure: false,
    carbon_footprint: false,
    payments: false
  }

  const [ userModalOpen, setUserModalOpen ] = useState(false)
  const [ editModalOpen, setEditModalOpen ] = useState(false)
  const [ deleteUserModalOpen, setDeleteUserModalOpen ] = useState(false)
  const [ selectedInvite, setSelectedInvite ] = useState(defaultInvite)
  const [ errors, setErrors ] = useState(defaultErrors)
  const [ newInvite, setNewInvite ] = useState(defaultInvite)
  const [ permissions, setPermissions ] = useState(defaultPermissions)
  const [ invites, setInvites ] = useState([])

  const invitesColDefs = [
    {field: "name", flex: 2, cellRenderer: (props) => (props.data.accepted ? props.data.user.name : props.data.name )},
    {field: "email", flex: 2, cellRenderer: (props) => (props.data.accepted ? props.data.user.email : props.data.email )},
    {field: "accepted", flex: 1,
      headerComponent: (props) => <div className="centeredHeader">Accepted?</div>,
      cellClass: "grid-cell-centered",
      cellRenderer: (props) => (props.data.accepted && "✔️")
    },
    {field: "measure", flex: 1,
      headerComponent: (props) => <div className="centeredHeader">Measure</div>,
      cellClass: "grid-cell-centered",
      cellRenderer: (props) => (props.data.permissions?.measure && "✔️")
    },
    {field: "carbon_footprint", flex: 1,
      headerComponent: (props) => <div className="centeredHeader">Carbon Footprint</div>,
      cellClass: "grid-cell-centered",
      cellRenderer: (props) => (props.data.permissions?.carbon_footprint && "✔️")
    },
    {field: "payments", flex: 1,
      headerComponent: (props) => <div className="centeredHeader">Payment</div>,
      cellClass: "grid-cell-centered",
      cellRenderer: (props) => (props.data.permissions?.payments && "✔️")
    },
    {field: "id", flex: 0.5, headerComponent: (props) => <div className="centeredHeader">Edit</div>,
      cellRenderer: (props) => (
      <div onClick={() => {setSelectedInviteById(props.data.id); setEditModalOpen(true); setUserModalOpen(true)}} className="icon-container edit"><EditIcon/></div>
    )},
    {field: "id", flex: 0.5, headerComponent: (props) => <div className="centeredHeader">Copy Link</div>,
      cellRenderer: (props) => (
      <div onClick={() => {copyInviteLink(props.data)}} className="icon-container edit"><ContentCopyIcon/></div>
    )},
  ]

  function copyInviteLink(invite) {
    console.log(invite)
    const inviteLink = process.env.REACT_APP_ROOT_URL + '/signup?key=' + invite.invite_link
    navigator.clipboard.writeText(inviteLink)
    console.log(inviteLink)
  }

  const autoSizeStrategy = {
    type: 'fitGridWidth',
  };

  const defaultColDef = {
    flex: 1,
    resizable: false,
    sortable: false,
    wrapText: true,
    cellStyle: {"wordBreak": "normal"},
  };

  const updateSelectedUserFirstName = (e) => {
    setSelectedInvite({...selectedInvite, first_name: e.target.value})
  }

  const updateSelectedUserLastName = (e) => {
    setSelectedInvite({...selectedInvite, last_name: e.target.value})
  }

  const updateSelectedUserEmail = (e) => {
    setSelectedInvite({...selectedInvite, email: e.target.value})
  }

  const updateSelectedUserMeasure = (e) => {
    setSelectedInvite({...selectedInvite, measure: !selectedInvite.measure})
  }

  const updateInvitePermissions = (e) => {
    const newPermissions = {...permissions}
    newPermissions[e.target.name] = e.target.checked
    setPermissions(newPermissions)
  }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  useEffect(() => {
    axios.get('/api/users/me/invites').then((res) => {
      setInvites(res.data)
    })
  }, [])

  const AccessModule = ({title, label, values, name, onChange}) => (
    <div className="accessModule">
      <div>
        <div className="body3">
          {title}
        </div>
        <div className="micro">
          {label}
        </div>
      </div>
      <div className="accessModuleSwitch">
        <Switch
          name={name}
          onChange={onChange}
          checked={values[name]}
        />
      </div>
    </div>
  )

  const updateInvite = (e) => {
    const updatedInvite = {...newInvite}
    updatedInvite[e.target.name] = e.target.value
    setNewInvite(updatedInvite)
  }

  const saveInvite = (id) => {
    const payload = {
      "invite": {
        permissions: permissions
      }
    }
    axios.put(`/api/invites/${id}`, payload).then((res) => {
      axios.get('/api/users/me/invites').then((res) => {
        setInvites(res.data)
        setNewInvite(defaultInvite)
        setPermissions(defaultPermissions)
        setUserModalOpen(false)
        setEditModalOpen(false)
      })
    })
  }

  const updatePermissions = (e) => {
    const updatedPermissions = {...permissions}
    updatedPermissions[e.target.name] = e.target.checked
    console.log(updatedPermissions)
    setPermissions(updatedPermissions)
  }

  const createInviteModalBody = (
    <div> 
      <div>
        <LabeledField
          name="name"
          type="text"
          label="Name"
          values={newInvite}
          formErrors={errors}
          onChange={updateInvite}
        />
      </div>
      <div>
        <LabeledField
          name="email"
          type="email"
          label="Email"
          values={newInvite}
          formErrors={errors}
          onChange={updateInvite}
        />
      </div>
      <div className="accessModules">
        <h4>
          Access 
        </h4>
        <div className="micro">
          By default, invited users can view the Home page, edit the Sustainability and Planning pages, and 
          receive notifications related to the company’s ClimateHound account. <br/><br/>
          Access to the following pages, which may contain sensitive information,
           is not granted by default but can be enabled below.
        </div>
        <br/>
        <div>
        <AccessModule 
          title="Measure" 
          label="Enabling allows user to view and edit the company’s Carbon Footprint Calculation." 
          name="measure"
          values={permissions}
          onChange={updatePermissions}
        />
        <AccessModule 
          title="Carbon Footprint" 
          label="Enabling allows user to view the company’s Carbon Footprint." 
          name="carbon_footprint"
          values={permissions}
          onChange={updatePermissions}
        />
        <AccessModule 
          title="Payment" 
          label="Enabling allows user to view and edit Saved Payment Methods." 
          name="payments"
          values={permissions}
          onChange={updatePermissions}
        />
        </div>
      </div>
    </div>
  )

  const editInviteModalBody = (
    <div> 
      <div>
        <LabeledField
          name="name"
          type="text"
          label="Name"
          disabled={true}
          values={selectedInvite}
          formErrors={errors}
          onChange={updateInvite}
        />
      </div>
      <div>
        <LabeledField
            name="email"
            type="email"
            label="Email"
            disabled={true}
            values={selectedInvite}
            formErrors={errors}
            onChange={updateSelectedUserEmail}
        />
      </div>
      <div className="accessModules">
        <h4>
          Access
        </h4>
        <div className="micro">
          By default, invited users can view the Home page, edit the Sustainability and Planning pages, and 
          receive notifications related to the company’s ClimateHound account. <br/><br/>
          Access to the following pages, which may contain sensitive information, is not granted by default 
          but can be enabled below.
        </div><br/>
        <div>
        <AccessModule 
          title="Measure" 
          label="Enabling allows user to view and edit the company’s Carbon Footprint Calculation." 
          name="measure"
          values={permissions} 
          onChange={updateInvitePermissions} 
        />
        <AccessModule 
          title="Carbon Footprint" 
          label="Enabling allows user to view the company’s Carbon Footprint." 
          name="carbon_footprint"
          values={permissions} 
          onChange={updateInvitePermissions} 
        />
        <AccessModule 
          title="Payment" 
          label="Enabling allows user to view and edit Saved Payment Methods." 
          name="payments"
          values={permissions} 
          onChange={updateInvitePermissions} 
        />
        </div>
      </div>
    </div>
  )

  const setSelectedInviteById = (id) => {
    const foundInvite = invites.find((user) => (user.id === id))
    console.log('found', foundInvite)
    setSelectedInvite(foundInvite)
    setPermissions(foundInvite.permissions)
  }

  const closeModal = () => {
    setUserModalOpen(false)
    setEditModalOpen(false)
    setDeleteUserModalOpen(false)
    setSelectedInvite(defaultInvite)
    setErrors(defaultErrors)
  }

  const openDeleteModal = () => {
    setUserModalOpen(false)
    setEditModalOpen(false)
    setDeleteUserModalOpen(true)
  }

  const createInvite = () => {
    const payload = {
      invite: {
        name: newInvite.name,
        email: newInvite.email,
        permissions: permissions
      }
    }
    axios.post(`/api/users/me/invites`, payload).then((res) => {
      setInvites(res.data)
      setNewInvite(defaultInvite)
      setPermissions(defaultPermissions)
      setUserModalOpen(false)
      setEditModalOpen(false)
    })
  }


  let modalActions

  if (!editModalOpen) {
    let hasEmptyFieldsAndIsntValid = newInvite.name.length === 0 || !(newInvite.email?.length > 0 && validateEmail(newInvite.email))
    modalActions = (
      <div>
        <div className="modalButtons">
          <input type="button" 
            value="Cancel" 
            className="small" 
            onClick={closeModal} 
          />
          <input 
            type="button"
            value="Invite"
            className="positive small"
            disabled={hasEmptyFieldsAndIsntValid}
            onClick={createInvite}
          />
        </div>
      </div>
    )
  } else {
    modalActions = (
      <div>
        {selectedInvite.accepted && <input type="button" className="skeleton deleteUserPermissionsButton" value="Delete User" onClick={openDeleteModal}/>}
        {!selectedInvite.accepted && <input type="button" className="skeleton deleteUserPermissionsButton" value="Delete Invite" onClick={openDeleteModal}/>}
        <div className="modalButtons">
          <input type="button" 
            value="Cancel" 
            className="small" 
            onClick={closeModal} 
          />
          <input 
            type="button"
            value="Save"
            className="positive small"
            disabled={false}
            onClick={() => (saveInvite(selectedInvite.id))}
          />
        </div>
      </div>
    )
  }

  function deleteUserOrInviteByInvite(inviteId) {
    axios.delete('/api/invites/' + inviteId).then((res) => {
      window.location = window.location
    })
  }

  const deleteModalActions = (
    <div className="modalButtons">
      <input type="button" value={"Remove"} className="destructive small" onClick={() => {deleteUserOrInviteByInvite(selectedInvite.id)}} />
      <input type="button" value="Cancel" className="small" onClick={closeModal} />
    </div>
    )

  const startNewInvite = () => {
    setPermissions(defaultPermissions)
    setNewInvite(defaultInvite)
    setUserModalOpen(true)
  }
  

  return (
    <div className="userProfileTable">
      <Modal
        title={"Remove User?"}
        open={deleteUserModalOpen}
        close={closeModal}
        bodyDom={
          <div className="body3">
            This will permanently delete <b>{selectedInvite.accepted ? selectedInvite.user.name : selectedInvite.name}</b> from ClimateHound.
          </div>
        }
        actionsDom={deleteModalActions}
      />
      <Modal
        className="employeeDataModal"
        title={(editModalOpen ? "Edit" : "Invite") + " User"}
        size="large"
        open={userModalOpen}
        close={closeModal}
        bodyDom={editModalOpen ? editInviteModalBody : createInviteModalBody}
        actionsDom={modalActions}
      />
      <div className="userTableHeader">
        <div className="userTableHeaderText">
          <div className="body1">My Team</div>
          <div className="body4">
            {!!invites.length && `${invites.length} total`}
          </div>
        </div>
        <IconButton value="Invite User" position="left" className="iconButton positive" onClick={startNewInvite} icon={<AddIcon/>}/>
      </div>
      <br />
      <div>
        <div className="ag-theme-quartz full-container-table">
          <AgGridReact
            domLayout='autoHeight'
            suppressDragLeaveHidesColumns
            rowData={invites}
            columnDefs={invitesColDefs} 
            autoSizeStrategy={autoSizeStrategy}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate="<span>Invite your team to customize permissions</span>"
            enableCellTextSelection
            ensureDomOrder
          />
        </div>
      </div>
    </div>
  )
}
