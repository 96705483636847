import React, { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from './PaymentForm'
import Modal from '../Containers/Modal'
import axios from 'axios'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const AddPaymentMethodModal = ({ setIsOpen, paymentMethodToUpdate, onPaymentMethodCreated }) => {
  const [ clientSecret, setClientSecret ] = useState('')

  useEffect(() => {
    axios.get(`/api/payments/setup_intents`).then((res) => {
      setClientSecret(res.data.client_secret)
    })
  }, [])

  return (

    <Modal
      title="Add Payment Method"
      subtitle="Add a new payment method to your account. You can then set it to default if desired."
      open={() => setIsOpen(true)}
      close={() => setIsOpen(false)}
      className="paymentMethodModal"
      bodyDom={
        <div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm clientSecret={clientSecret} onPaymentMethodCreated={onPaymentMethodCreated} />
            </Elements>
          )}
        </div>
      }
      actionsDom={
        <div>
          <input
            type="button"
            value="Cancel"
            className="small"
            onClick={() => setIsOpen(false)}
          />
        </div>
      }
    />
  )
}

export default AddPaymentMethodModal
