import React from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'

import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { ReactComponent as ClockIcon } from '../../assets/Clock.svg'

export default function CalculationActionButton({ user }) {

  const navigate = useNavigate()

  const startNextCalc = () => {
    axios.post(`/api/users/${user.id}/start_next_calculation`).then((res) => {
      window.location = '/questionnaire/start'
    })
  }

  const needToDoCurrentYear = user && user.most_recent_calc_year < new Date().getFullYear() - 1
  const currentYearInProgress = user && user.most_recent_qnn_year === new Date().getFullYear() - 1
  const hasNeverDoneCalculation = !user.most_recent_qnn_year
  const nextCalcYear = needToDoCurrentYear ? new Date().getFullYear() - 1 : new Date().getFullYear()

  // if the user is new to the platform and has no calculated footprint
  const calcActionStartFirst = (
    <div className={classnames('userMetrics', 'hasNotStarted')} onClick={startNextCalc}>
      <div className="userMetricMainIcon">
        <ClockIcon />
        <div className="userMetricMainText">
          <div className="body3">
            Complete a carbon footprint
          </div>
          <div className='micro'>
             Start your calculation today!
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon />
      </div>
    </div>
  )

  const dueDateText = (calcDue) => {
    if(!calcDue) {
      return "Due today!"
    } else if(user.calc_due_in_days > 0) {
      return `Due in ${user.calc_due_in_days} days`
    } else {
      return `Due ${user.calc_due_in_days * -1} days ago`
    }
  }

  // if the user has 1 or more calculations and needs to start the next
  const calcActionStartNext = (
    <div className={classnames('userMetrics', 'hasNotStarted')} onClick={startNextCalc}>
      <div className="userMetricMainIcon">
        <ClockIcon />
        <div className="userMetricMainText">
          <div className="body3">
            Start {nextCalcYear} calculation
          </div>
          <div className='micro'>
             {dueDateText(user.calc_due_in_days)}
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon/>
      </div>
    </div>
  )

  // if the user is in the middle of a carbon footprint
  const calcActionContinue = (
    <div className={classnames('userMetrics', 'hasNotStarted')} onClick={() => {navigate('/questionnaire/start')}}>
      <div className="userMetricMainIcon">
        <ClockIcon />
        <div className="userMetricMainText">
          <div className="body3">
            Continue {nextCalcYear} calculation
          </div>
          <div className='micro'>
            {dueDateText(user.calc_due_in_days)}
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon />
      </div>
    </div>
  )

  // if the user is done and waiting for next year
  const calcActionCompleted = (
    <div className={classnames('userMetrics')} >
      <div className="userMetricMainIcon">
        <ClockIcon />
        <div className="userMetricMainText">
          <div className="body3">
            You're up to date
          </div>
          <div className='micro'>
            Next calculation available on 1/1/{new Date().getFullYear() + 1}
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon />
      </div>
    </div>
  )

  if (!needToDoCurrentYear) {
    return calcActionCompleted
  } else {
    if (currentYearInProgress) {
      return calcActionContinue
    } else {
      if (hasNeverDoneCalculation) {
        return calcActionStartFirst
      } else {
        return calcActionStartNext
      }
    }
  }
}
