import React from 'react'

export default function LabeledField(props) {
  const errors = props.formErrors || {}
  return (
    <div className={props.className || null}>
      {props.label && (<label className={errors[props.name] ? 'error' : ''}>{props.label}</label>)}
      <input
        className={errors[props.name] ? 'error' : ''}
        name={props.name} 
        type={props.type || 'text'}
        placeholder={props.placeholder || props.label}
        onChange={props.onChange}
        value={props.values[props.name]}
        autoComplete={props.autocomplete || 'off'}
        ref={props.ref}
        disabled={props.disabled}
      />
      {errors[props.name] && (<div className="errorMessage">{errors[props.name]}</div>)}
    </div>
  )
}
