const reduction_strategy_steps = {
  'null-strategy': [
    'This is the first step. Try and remember that.',
    'This is the step that is after the first.',
    'We did not expect you to make it this far tbh!'
  ],
  'pv-panels': [
    <span><b>1. Locate solar provider: </b>Find your local solar provider and request an estimate based on the % of your electricity needs you want to meet through solar PV.</span>,
    <span><b>2. Ask about incentives: </b>Your solar provider should be well-versed on rebates and tax-incentives in your city. </span>,
    <span><b>3. Update assumptions: </b>Once you have your estimate, update the assumptions to better represent your actual quote and potential impact.</span>,
    <span><b>4. Finalize installation: </b>Once the install is finished and you have your invoice, set the reduction to completed and include the final cost/watt.</span>
  ],
  'work-from-home': [
    <span><b>Step 1:</b> Identify employees that can work remotely at least part-time.</span>,
    <span><b>Step 2: </b>Encourage employees to do administrative tasks away from the office during business hours.</span>,
    <span><b>Step 3: </b>Start your work from home program.</span>
  ],
  'eco-driving': [
    <span><b>1. Educate employees: </b>Prepare a workshop (even a simple email) explaining eco-driving, its benefits for the environment, and cost savings.</span>,
    <span><b>2. Policy implementation: </b>Encouraging eco-driving in your employee handbook or onboarding materials, including speed limits and fuel efficiency guidelines for company vehicles.</span>,
    <span><b>3. Monitoring system: </b>Consider installing monitoring systems in company vehicles to track speed and fuel efficiency.</span>,
    <span><b>4. Reward system: </b>Consider a reward system for employees who consistently follow eco-driving practices.</span>,
  ],
  'reduce-air-travel': [
    <span><b>1. Policy Review:</b> Review your company's travel policies and identify areas where changes can lead to fewer flights.</span>,
    <div className="reductionStrategyStepColumn"><b>2. Things to consider:</b>
    <span className="subStep"><b>- Video calls and online collaboration: </b>Consider the use of video conferencing and virtual meetings or online collaboration tools to reduce the need for physical travel. </span>
    <span className="subStep"><b>- Consolidate Trips:</b> If travel is necessary try to consolidate multiple business needs into each trip to reduce the number of total flights.</span>
    <span className="subStep"><b>- Train Travel:</b> Consider alternative modes of transport like trains for shorter distances which are usually less carbon-intensive.</span>
    <span className="subStep"><b>- Flexible Working Arrangements: </b>Allow more remote working so employees don’t need to travel frequently to different locations.</span>
    <span className="subStep"><b>- Sustainable Airlines:</b> Choose airlines that have strong environmental policies or offer carbon offset programs.</span></div>,
    <span><b>3. </b>Implement methods of reducing travel and record those results into this policy</span>,
  ],
  'turn-off-lights': [
    <span><b>1.</b> Audit your buildings to understand the scope of lighting fixtures and daily routines</span>,
    <span><b>2. Policy Implementation: </b>Implement a clear policy for turning off lights when not in use, especially during non-working hours.</span>,
    <span><b>3. Awareness Campaign: </b>Conduct an educational campaign explaining energy savings from turning off lights and its environmental impact. For example, install reminders to turn off lights near switches throughout your facilities.</span>,
    <div className="reductionStrategyStepColumn"><b>4. Further things to consider when turning off lights:</b>
    <span className="subStep"><b>- Automated Systems:</b> Consider investing in motion sensor lights or timed lighting systems that turn off automatically when not in use.</span>,
    <span className="subStep"><b>- Regular Feedback:</b> Provide feedback on energy savings achieved to motivate continued participation.</span></div>,
  ],
  'switch-to-led': [
    <span><b>1. Assess Current Lighting:</b> Start by auditing your existing lighting setup. Write down the bulb types, wattages, and total number of all current bulbs.</span>,
    <span><b>2. Purchase and Install New Lighting: </b>Procure equivalent wattage lights and begin the installation process. You may need professionals for complex fixtures.</span>,
    <span><b>3. Recycle Old Bulbs: </b>Make sure to properly dispose of or recycle your old bulbs according to local regulations.</span>,
    <span><b>4. Review and Expand: </b>Review the impact of the LED switch in your emissions planning and consider expanding LED use to other areas like outdoor lighting, signage, etc.</span>,
  ],
  'repair-insulation': [
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy to repair damaged or missing insulation including </span>
      <span className="subStep firstSubStep">a.) in-scope equipment/areas such as hot and cold pipes, pipe elbows and joints, valves, flanges, water heaters, tanks, heat exchangers, and large boiler parts</span>
      <span className="subStep">b.) defining roles and responsibilities.</span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy. </span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
  'maintain-kitchen-equipment': [
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy to maintain kitchen equipment including </span>
      <span className="subStep firstSubStep">a.)  in-scope equipment such as dishwashers, fryers, steam cookers, ranges, and refrigeration</span>
      <span className="subStep">b.) roles and responsibilities. Policy should include general servicing.</span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy. </span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
  'water-steam-leaks': [
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy including </span>
      <span className="subStep firstSubStep">a.)  in-scope equipment such as toilets, faucets, steam and condensate systems including steam traps </span>
      <span className="subStep">b.) roles and responsibilities.</span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy including fixing any leaks identified. </span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
  "source-sustainable-agricultural-products": [
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy to source more sustainable food and ingredients including</span>
      <span className="subStep firstSubStep">a.) in-scope food and ingredients </span>
      <span className="subStep">b.) roles and responsibilities.</span>
      <br/>
      <span> 
        Sustainable agricultural practices include rotating crops and embracing diversity, planting cover crops, reducing tillage, and applying integrated pest management. Certifications for agricultural products that you might look for include Organic, Regenerative Organic, Fair Trade, Bonsucro, Roundtable on Sustainable Palm Oil, Roundtable on Responsible Soy, and Rainforest Alliance.
      </span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy. </span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
  'close-doors': [
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy to close doors when not in use including </span>
      <span className="subStep firstSubStep">a.)  in-scope equipment/areas such as refrigeration equipment, ice machines, ovens, and exterior doors </span>
      <span className="subStep">b.) roles and responsibilities.</span>
      <br/>
      <span>If you live in a place where leaving the doors open means turning off the HVAC then please do proceed!</span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy. </span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
  'turn-off-equipment': [    
    <div className="reductionStrategyStepColumn"><span><b>Step 1:</b> Define policy to turn off equipment when not in use including </span>
      <span className="subStep firstSubStep">a.)  in-scope equipment  </span>
      <span className="subStep">b.) roles and responsibilities</span>
      <span className="subStep">c.) startup/shutdown schedule which may include a lunch break shutdown.</span>
      <br/>
      <span>Policy could include floor fans, conveyors, air compressors, and backup boilers as well as kitchen equipment such as second cooklines, broilers, dipperwells, and burners.</span>
    </div>,
    <span> <b>Step 2: </b>Solicit stakeholder/employee feedback on the policy. </span>,
    <span> <b>Step 3: </b>Finalize and implement policy</span>,
    <span> <b>Step 4: </b>Set the initiative to completed and provide details.</span>,
  ],
}

export default reduction_strategy_steps
