export default {
  "pv-panels": {
    emissions: "The total GHGs reduced by this initiative are estimated using your total kWh of consumed energy during last year, multipled by the environmental impact of your local energy grid, and factored against the amount of proposed PV energy generation over the expected lifetime of the hardware.",
    dollars: "We are able to estimate your total dollars saved across the expected lifetime of most solar PV systems by multiplying your total kWh of energy consumed last year by the average statewide price/kWh, then by the % of your electricity needs you are aiming to meet with solar PV panels, and subtracting the upfront cost and expected maintenance.",
    upfront: "The approximate price to install solar is generated by taking your total kWh of energy consumed during your most recent carbon footprint and adjusting that data to account for your average peak wattage. Once you have a quote, you can adjust the cost/watt below."
  },
  "eco-driving": {
    emissions: "This estimate is based on your emissions associated with company vehicles during the most recent carbon footprint, percentage highway miles, and how much the top cruising speed will be reduced.",
    dollars: "This estimate is based on the average cost of gasoline and the amount of fuel you are able to save with eco-driving practices.",
    upfront: "There's no upfront cost for this initiative."
  },
  "work-from-home": {
    emissions: "This assumes an average fuel economy of 24.2 MPG.",
    dollars: "Your employees will save some money, while your company saves on emissions.",
    upfront: "There's no upfront cost for this initiative."
  },
  "reduce-air-travel": {
    emissions: "This initiative is estimated on a spend-basis for business-related air travel.",
    dollars: "The amount of dollars you save will grow proportionally to the amount of emissions you have avoided.",
    upfront: "There's no upfront cost for setting a policy to reduce your company's air travel."
  },
  "turn-off-lights": {
    emissions: "The emissions reduction is estimated based on your total consumption of energy due to lighting, the number of lighting hours reduced, and the emissions associated with creating electricity on your local grid.",
    dollars: "We're able to estimate savings based on the average cost of electricity in your region and the amount of hours you are able to reduce lighting usage.",
    upfront: "It doesn't really cost anything to start turning off the lights, unless you choose to implement motion sensors."
  },
  "switch-to-led": {
    emissions: "The emissions reduction is estimated based on electricity savings from reduced wattage and the emissions associated with creating electricity on your local grid.",
    dollars: "Electricity savings from reduced wattage, plus savings on replacement bulbs due to longer life.",
    upfront: "Upfront cost includes bulbs but does not account for your time or hiring someone to replace them."
  },
  "repair-insulation": {
    emissions: "This initiative reduces your energy consumption, and thereby reduces GHG emissions.",
    dollars: "This initiative reduces your utility bill but the savings aren't straightforward to quantify.",
    upfront: "This initiative only requires time and effort.",
  },
  "maintain-kitchen-equipment": {
    emissions: "This initiative reduces your energy consumption, and thereby reduces GHG emissions.",
    dollars: "This initiative reduces your utility bill but the savings aren't straightforward to quantify.",
    upfront: "This initiative only requires time and effort.",
  },
  'water-steam-leaks': {
    emissions: "This initiative reduces your energy consumption, and thereby reduces GHG emissions.",
    dollars: "This initiative reduces your utility bill but the savings aren't straightforward to quantify.",
    upfront: "This initiative only requires time and effort.",
  },
  "source-sustainable-agricultural-products": {
    emissions: "This initiative reduces the GHG emissions in your supply chain associated with purchasing goods.",
    dollars: "Savings aren't straightforward to quantify.",
    upfront: "Costs aren't straightforward to quantify.",
  },
  "close-doors": {
    emissions: "This initiative reduces your energy consumption, and thereby reduces GHG emissions.",
    dollars: "This initiative reduces your utility bill but the savings aren't straightforward to quantify.",
    upfront: "This initiative only requires time and effort.",
  },
  "turn-off-equipment": {
    emissions: "This initiative reduces your energy consumption, and thereby reduces GHG emissions.",
    dollars: "This initiative reduces your utility bill but the savings aren't straightforward to quantify.",
    upfront: "This initiative only requires time and effort.",
  },
}