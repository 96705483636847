import React, { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

const PaymentForm = ({ clientSecret, onPaymentMethodCreated }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) return

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    })

    setLoading(false)

    if (error) {
      setError(error.message)
      console.error(error)
    } else {
      onPaymentMethodCreated(setupIntent.payment_method)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <button type="submit" disabled={!stripe || loading} className="button positive" >
        {loading ? 'Processing...' : 'Save Payment Method'}
      </button>
    </form>
  )
}

export default PaymentForm
