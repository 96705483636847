import react from 'react'
import { useAuth } from '../../providers/AuthProvider'

export default function PermissionsGate({roles, children}) {
  const auth = useAuth()
  const user = auth.userInfo

  if (user.is_acct_owner) {
    return children
  } else if(!roles) {
    console.log("no roles defined in gate check, not rendering content")
    return null
  } else {
    for (let i = 0; i < roles.length; i++) {
      if (user.permissions[roles[i]]) {
        return children
      }
    }
  }
}
