import React, {useEffect} from 'react'
import Mousetrap from 'mousetrap'

export default function Modal(props) {
  let loading = false

  if (props.loading !== undefined) {
    loading = props.loading
  }

  useEffect(() => {
    Mousetrap.bind('esc', () => {
      props.close()
    })
    return () => {
      Mousetrap.unbind('esc')
    }
  }, [])

  return (
    <div className={`modalContainer ${props.open ? 'open' : 'closed'}`}>
      <div className="grayOverlayHitzone" onClick={props.close}>
      </div>
      <div className={`${loading ? 'loading' : ''} modal ${(props.size === 'large') ? 'large' : ""} ${props.className || ' '}`}>
        <div className="modalLoading">
          <div className="lds-facebook"><div></div><div></div><div></div></div>
        </div>
        <header>
          <h4>{props.title}</h4>
          <div className="body3">{props.subtitle}</div>
        </header>
        <div className="modalBody">
          { props.bodyDom }
        </div>
        <div className="modalActions">
          { props.actionsDom }
        </div>
      </div>
    </div>
  )
}
