import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import CheckBox from '../Forms/CheckBox'
import LabeledSelect from '../Forms/LabeledSelect'

import ReductionStrategyTile from './ReductionStrategyTile'
import ReductionStrategyEditor from './ReductionStrategyEditor'
import LabeledField from '../Forms/LabeledField';
import { initiativeIsDone, initiativeIsCompleted } from './reductionStrategyUtils';

export default function ReductionStrategies(props) {

  const [ strats, setStrats ] = useState([])
  const [ openStratId, setOpenStratId ] = useState(null)
  const [ isEditorLoading, setIsEditorLoading ] = useState(false)
  const [ strategyFilter, setStrategyFilter ] = useState({sort: 'i', search: ''})
  const [ tabValue, setTabValue ] = useState(1)
  const [ selectedReductionPlanCheckboxes, setSelectedReductionPlanCheckboxes ] = useState({reductions: true, policies: false})

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  useEffect(() => {
    setStratsFromApi()
  }, [])

  const location = useLocation();

  // Helper function to get query parameters
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const slug = queryParams.get('slug');
    if (slug) {
      const slugStrat = strats.find(strat => strat.slug === slug)
      if(slugStrat) {
        setOpenStratId(slugStrat.id);
      }
    }
  }, [location.search, strats]);


  function setStratsFromApi() {
    axios.get('/api/reduction_strategies/for_user' + (props.userId ? `?user_id=${props.userId}` : "")).then((res) => {
      const totalStrats = res.data.reduction_strategies;
      setStrats((totalStrats))

      let totalForecastKgs = 0
      let totalGrowthForecastKgs = 0

      for (let i = 0; i < res.data.reduction_strategies.length; i++) {
        if (res.data.reduction_strategies[i].is_in_forecast) {
          if(res.data.reduction_strategies[i].is_lifetime_impact) {
            totalForecastKgs += res.data.reduction_strategies[i].kgco2e_reduced / res.data.reduction_strategies[i].expected_lifetime
          } else {
            totalGrowthForecastKgs += res.data.reduction_strategies[i].kgco2e_reduced
          }
        }
      }
      props.setForecastKgs(totalForecastKgs)
      props.setGrowthForecastKgs(totalGrowthForecastKgs)
    })
  }

  const findStratFromId = (rsId) => strats.find((rs) => (rs.id === rsId))

  const toggleReductionStep = (rsId, stepIdx) => {
    const rs = findStratFromId(rsId)
    const mask = [...rs.completed_steps_mask]
    for (let i = 0; i <= stepIdx; i++) {
      mask[i] = i === stepIdx ? !mask[i] : !!mask[i]
    }
    axios.put(`/api/reduction_strategies/${rsId}`, {completed_steps_mask: mask}).then(() => (setStratsFromApi()))
  }

  const saveDraftInputs = (rsId, inputs) => {
    setIsEditorLoading(true)

    for ( const key in inputs ) {
      if (typeof inputs[key] === 'string' && inputs[key].indexOf('.') > -1) {
        inputs[key] = parseFloat(inputs[key])
      }
    }
    console.log('inputs', inputs)

    axios.put(`/api/reduction_strategies/${rsId}`, {inputs: inputs}).then(() => {
      setStratsFromApi()
      setTimeout(() => setIsEditorLoading(false), 1000)
    })
  }

  const resetEverything = (rsId) => {
    axios.delete(`/api/reduction_strategies/for_user`).then(() => (setStratsFromApi()))
  }

  const updateActionPlan = (rsId) => {
    setIsEditorLoading(true)
    axios.put(`/api/reduction_strategies/${rsId}`, {is_in_action_plan: true, is_in_forecast: true}).then(() => {
      setStratsFromApi()
      setTimeout(() => setIsEditorLoading(false), 1000)
    })
  }

  const updateStatus = (rsId, newStatus) => {
    setIsEditorLoading(true)
    axios.put(`/api/reduction_strategies/${rsId}`, {status: newStatus}).then(() => {
      setStratsFromApi()
      setTimeout(() => setIsEditorLoading(false), 1000)    
    })
  }

  const updateYear = (rsId, year) => {
    setIsEditorLoading(true)
    axios.put(`/api/reduction_strategies/${rsId}`, {estimated_completion_year: year}).then(() => {
      setStratsFromApi()
      setTimeout(() => setIsEditorLoading(false), 1000)    
    })
  }

  const toggleReductionInForecast = (rsId) => {
    const rs = findStratFromId(rsId)
    axios.put(`/api/reduction_strategies/${rsId}`, {is_in_forecast: !rs.is_in_forecast}).then(() => (setStratsFromApi()))
  }

  const filteredStrats = () => {
    const newStrats = strats.slice().filter(strat => {
      if(strategyFilter.search && !strat.name.includes(strategyFilter.search))
      {return false} else {
        return true
      }
    }).sort( (a, b) => {
      if(strategyFilter.search === 'i') {
        return a.kgco2e_reduced - b.kgco2e_reduced
      } else if(strategyFilter.search === 'c') {
        return a.upfront_cost - b.upfront_cost
      } else if(strategyFilter.search === 's') {
        return a.dollars_saved - b.dollars_saved
      } else if(strategyFilter.search === 'a') {
        return a.name > b.name ? 1 : -1
      }
    })
    return newStrats;
  }

  const sortByInitiativeStatus = (a, b) => {
    // Check if `a` and `b` meet the condition
    const aMatch = (a.status === 'IN_PROGRESS' || a.status === 'STARTED') && !a.is_in_action_plan;
    const bMatch = (b.status === 'IN_PROGRESS' || b.status === 'STARTED') && !b.is_in_action_plan;

    // If both match or both don't match, keep their relative order
    if (aMatch === bMatch) return 0;

    // If `a` matches and `b` does not, place `a` before `b`
    if (aMatch) return 1;

    // If `b` matches and `a` does not, place `b` before `a`
    return -1;
  };

  const simulateStrats = filteredStrats().filter((rs) => (!rs.is_in_action_plan && !initiativeIsCompleted(rs.status))).sort(sortByInitiativeStatus)
  const actionPlanStrats = filteredStrats().filter((rs) => (rs.is_in_action_plan && !initiativeIsDone(rs.status)))
  const completedStrats = filteredStrats().filter((rs) => (initiativeIsDone(rs.status)))

  const stratIsActive = (rs) => Object.keys(rs.inputs).filter(input => rs.inputs[input]).length || rs.category === "POLICY"

  const mapStratsDom = (strats) => {
    if(!strats.length) {return;}
    return strats.sort((a, b) => {
      if (stratIsActive(a)) return -1;  // Move `a` to a lower index
      if (stratIsActive(b)) return 1; // Move `b` to a higher index
      return 0;  // Maintain relative order
    }).map((rs) => {
      const strategyIsActive = stratIsActive(rs)

      return (
        <ReductionStrategyTile
          key={rs.id}
          rs={rs}
          strategyIsActive={strategyIsActive}
          openStrat={() => (setOpenStratId(rs.id))}
          toggleReductionInForecast={toggleReductionInForecast}
          updateActionPlan={updateActionPlan}
          setTabValue={setTabValue}
        />
      )
    })
  }

  const rsPanelDom = (rsId) => {
    const rs = findStratFromId(rsId)
    if (rs) {
      return (
        <ReductionStrategyEditor
          rs={rs}
          closeEditor={() => (setOpenStratId(null))}
          toggleReductionStep={toggleReductionStep}
          saveDraftInputs={saveDraftInputs}
          updateStatus={updateStatus}
          updateYear={updateYear}
          toggleReductionInForecast={toggleReductionInForecast}
          updateActionPlan={updateActionPlan}
          isLoading={isEditorLoading}
          setTabValue={setTabValue}
          userId={props.userId}
        />
      )
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const strategySearchOptions = [
    {label: 'Impact', value: 'i'}, 
    {label: 'Cost', value: 'c'},
    {label: 'Savings', value: 's'},
    {label: 'A-Z', value: 'a'},
  ]
  
  return (
    <>
      { rsPanelDom(openStratId) }
      {false && <button onClick={resetEverything}>RESET EVERYTHING!!!</button>}
      <div className="stratsSectionHolder">
        <div className="tabsContainer">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={tabValue} 
              onChange={handleTabChange} 
              aria-label="lab API tabs example" 
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "green",
                  color: 'black'
                }
              }}
            >
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 1 ? 'black' : 'gray'}}>Explore</span>} 
                value={1}
                disableRipple
              />
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 2 ? 'black' : 'gray'}}>Action Plan</span>} 
                value={2} 
                disableRipple
              />
              <Tab 
                className="tabLabel" 
                label={<span style={{ color: tabValue === 3 ? 'black' : 'gray'}}>Completed</span>} 
                value={3} 
                disableRipple
              />
            </Tabs>
          </Box>
        </div>
        <div>
          <div className="strategyFilterContainer">
            {false && <div>
              <div className="body4">
                Show initiative type
              </div>
              <div className='checkboxContainer'>
                <CheckBox name="reductions" label='Reductions' values={selectedReductionPlanCheckboxes} onChange={setSelectedReductionPlanCheckboxes} />
                <CheckBox name="policies" label='Policies' values={selectedReductionPlanCheckboxes} onChange={setSelectedReductionPlanCheckboxes} />
              </div>
            </div>}
            <div className="strategySortSearch">
              {false && <div className="sortStrategies">
                <LabeledSelect 
                  name="sort"
                  label={<span className="body4">Sort by</span>}
                  className="small"
                  placeholder="Sort by"
                  values={strategyFilter}
                  onChange={(e) => setStrategyFilter({...strategyFilter, sort: e.target.value})}
                  options={strategySearchOptions}
                />
              </div>}
              {false && <LabeledField
                className="searchStrategies"
                name='search'
                values={strategyFilter}
                onChange={(e) => setStrategyFilter({...strategyFilter, search: e.target.value})}
                placeholder='Search initiatives'
              />}
            </div>
          </div>
        </div>
      </div>
      <TabPanel value={tabValue} index={1} className={'stratTab'}>
          <div className="stratsSection">
            <div className="stratsList">
              { mapStratsDom(simulateStrats) }
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2} className={'stratTab'}>
          <div className="stratsSection">
            <div className="stratsList">
              { mapStratsDom(actionPlanStrats) }
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3} className={'stratTab'}>
          <div className="stratsSection">
            <div className="stratsList">
              { mapStratsDom(completedStrats) }
            </div>
          </div>
        </TabPanel>
      <div>
      </div>
    </>
  )
}
