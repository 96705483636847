import React, { useState, useEffect } from 'react'
import { Link, useParams, useOutletContext } from 'react-router-dom'

import catMap from '../../lib/catMap'

import axios from 'axios'

export default function QuestionnaireShow() {

  const { id } = useParams()
  const [ isLoading, setIsLoading ] = useOutletContext()


  const qnnDefault = {
    name: '',
    questions: []
  }
  const [ qnn, setQnn ] = useState(qnnDefault)
  useEffect(() => {
    axios.get('/api/questionnaires/' + id).then((res) => {
      // console.log(res)
      setQnn(res.data)
    })
  }, [])

  const summaryDefault = {
    name: '',
    progress: [],
    totalProgress: 0
  }
  const [ summary, setSummary ] = useState(summaryDefault)
  useEffect(() => {
    axios.get('/api/questionnaires/' + id + '/summary').then((res) => {
      // console.log(res)
      setSummary(res.data)
    })
  }, [])

  const [ user, setUser ] = useState({})
  useEffect(() => {
    if (summary?.user_id) {
      axios.get('/api/users/' + summary.user_id).then((res) => {
        setUser(res.data)
      })
    }
  }, [summary])

  const generateReport = () => {
    setIsLoading(true)
    axios.post('/api/questionnaires/' + id + '/generate_report').then((res) => {
      setIsLoading(false)
      window.location = window.location
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const notifyOfReport = () => {
    setIsLoading(true)
    axios.post('/api/users/' + user.id + '/notify_of_report', { report_id: qnn?.report?.id }).then((res) => {
      setIsLoading(false)
      window.location = window.location
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }

  const summaryHeaders = ['Section Name', 'Percent Done']
  const summaryProgress = []


  for (let catSlug in summary.progress) {
    const sectionProgress = []
    sectionProgress.push(catMap[catSlug].name)
    sectionProgress.push(`${summary.progress[catSlug].progress.toFixed(1)}%`)
    summaryProgress.push(sectionProgress)
  }

  const questionHeaders = [ 'ID', 'name id', 'Question', 'Status', 'Answer', 'Units' ]
  const statusMap = {
    0: 'UNANSWERED',
    1: 'ANSWERED',
    2: 'NOT_APPLICABLE',
    3: 'WAS_SKIPPED',
    4: 'HELP_REQUESTED',
    5: 'PENDING',
  }

  const sortQuestions = (a, b) => {
    if (a.name_id < b.name_id ) {
      return -1;
    } else if (a.name_id > b.name_id) {
      return 1;
    }
    return 0;
  }

  const renderAdminAnswerDisplay = (adminAnswerDisplay) => {
  if (!adminAnswerDisplay || !adminAnswerDisplay.labels || !adminAnswerDisplay.values) {
    return null;
  }

  const { labels, values } = adminAnswerDisplay;

  const tableRows = values.map((valRow, idx) => (
    <tr key={idx}>
      {valRow.map( (val, idx) => {
        return <td key={idx}>{val}</td>;
      } )}
    </tr>
  ));

  return (
    <table>
      <thead>
        <tr>
          {labels.map((label, idx) => (
            <th key={idx}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows}
      </tbody>
    </table>
  );
};

  return(
      <div className="adminPage">

        <header className="pageHeader">
          <h2>{ qnn.name }</h2>
        </header>

        <div className="table horizontal">
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{ qnn.id }</td>
              </tr>
              <tr>
                <td>Percent Complete</td>
                <td>{ summary.total_progress?.toFixed(1) }%</td>
              </tr>
              <tr>
                <td>Generate Report</td>
                <td>
                  <input type="button" 
                    onClick={generateReport} 
                    value="Generate" 
                    disabled={summary.total_progress !== 100 || summary.has_report} 
                  />
                  <input type="button" 
                    onClick={generateReport} 
                    value="RE-Generate" 
                    disabled={!(summary.total_progress !== 100 || summary.has_report)} 
                  />
                </td>
              </tr>
              <tr>
                <td>Has Report</td>
                <td>
                  { 
                    (summary.has_report && qnn.report?.id) ? (
                      <Link target="_blank" rel="noopener noreferrer" to={`/insights/details?user_id=${summary.user_id}&report_id=${qnn.report?.id}`}>
                        YES
                      </Link>
                    ) : 'no'
                  }
                </td>
              </tr>
              <tr>
                <td>Client Notified of Report</td>
                <td>
                  { 
                    qnn.report_notification_sent_at ? 'YES' : 'no'
                  }
                  <input type="button" 
                    onClick={notifyOfReport} 
                    value={`Send Report to ${user.email}`}
                    disabled={!user?.id || !qnn?.report?.id}
                  />
                </td>
              </tr>
              {qnn.report?.total_kgco2e && (
                <tr>
                  <td>Total kgCO2e</td><td>{ Math.round(qnn.report?.total_kgco2e * 100) / 100 }</td>
                </tr>
              )}
              <tr>
                <td>Data Review</td>
                <td>
                  { 
                    
                      <Link to={`/admin/questionnaires/${id}/data-review`}>
                        Click here for data review
                      </Link>
                    
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                { summaryHeaders.map((sumHead) => (<td>{sumHead}</td>)) }
              </tr>
            </thead>
            <tbody>
              { summaryProgress.map((secProg) => (<tr>{secProg.map((val) => (<td>{val}</td>))}</tr>)) }
            </tbody>
          </table>
        </div>

        <div className="table">
          <table>
            <thead>
              <tr>
                { questionHeaders.map((qHead) => (<td>{qHead}</td>)) }
              </tr>
            </thead>
            <tbody>
              {
                qnn.questions.sort(sortQuestions).map((q) => {
                  return (
                    <tr>
                      <td>{q.id}</td>
                      <td>{q.name_id}</td>
                      <td>{q.prompt_text.text}</td>
                      <td>{statusMap[q.status]}</td>
                      <td>
                        {renderAdminAnswerDisplay(q.admin_answer_display)}
                      </td>
                      <td>{q.unit.units ? q.unit.units.reduce((acc, cur, idx) => (acc + (idx ? " or " : "") + cur.name), "") : q.unit.name }</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

        <div className="table">
          {renderAdminAnswerDisplay(qnn.waste)}
        </div>

      </div>
  )
}
