import React, { useState, useEffect } from 'react'
import LabeledField from '../Forms/LabeledField'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'

import { useToast } from '../../providers/ToastProvider'

import { ReactComponent as StripeIcon } from '../../assets/Stripe.svg'
import UserTable from './UserTable'

import PermissionsGate from '../Auth/PermissionsGate'
import AddPaymentMethodModal from '../Payments/AddPaymentMethodModal'
import Modal from '../Containers/Modal'

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js"


export default function EditProfile() {

  const toast = useToast()

  const defaultUserInfo = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    bio: '',
    logoUrl: '',
    wordmarkUrl: ''
  }

  const [ searchParams ] = useSearchParams()
  const updatedParam = searchParams.get('updated')

  const [ userInfo, setUserInfo ] = useState(defaultUserInfo)
  const [ formErrors, setFormErrors ] = useState({})
  const [ updated, setUpdated] = useState(updatedParam)
  const [ userPaymentInfo, setUserPaymentInfo ] = useState({})
  const [ paymentModalOpen, setPaymentModalOpen ] = useState(false)
  const [ deletePaymentMethodModalIsOpen, setDeletePaymentMethodModalIsOpen ] = useState(false)
  const [ paymentMethodToDelete, setPaymentMethodToDelete ] = useState()

  useEffect(() => {
    axios.get('/api/payments/user_info').then((res) => {
      setUserPaymentInfo(res.data)
      console.log(res.data)
    })
  }, [])

  if(updated) {
    toast.setToast('Payment method updated', 3000, 'success')
    setUpdated(false)
    console.log('heck yea')
  }

  const updateUserInfo = (e) => {
    const newUserInfo = {...userInfo}
    newUserInfo[e.target.name] = e.target.value
    setUserInfo(newUserInfo)
    setFormErrors({})
    console.log(newUserInfo)
  }

  const getUserInfo = (callback) => {
    axios.get('/api/users/me').then((res) => {
      const newUserInfo = {
        firstName: res.data.first_name || '',
        lastName: res.data.last_name || '',
        companyName: res.data.company || '',
        email: res.data.email || '',
        bio: res.data.bio || '',
        logoUrl: res.data.logo_url || '',
        wordmarkUrl: res.data.wordmark_url || ''
      }
      callback(newUserInfo)
    })
  }

  const saveInfo = () => {
    const apiFormatInfo = {
      user: {
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        company: userInfo.companyName,
        email: userInfo.email,
        bio: userInfo.bio
      }
    }
    axios.put('/api/users/me', apiFormatInfo).then((res) => {
      console.log(res)
      toast.setToast('Profile updated 👍', 3000, 'success')
    })
  }

  const capitalizeWord = (word) => {
    return word[0].toUpperCase() + word.slice(1)
  }

  const openAddPaymentMethodModal = () => {
    setPaymentModalOpen(true)
  }

  useEffect(() => {
    getUserInfo((info) => setUserInfo(info))
  }, [])

  const setDefaultPaymentMethod = (paymentMethod) => {
    axios.post('/api/payments/set_default_method', { payment_method_id: paymentMethod.id }).then((res) => {
      window.location = window.location
    }).catch((e) => {
      console.log('error setting payment method!')
      console.log(e)
    })
  }

  const openDeletePaymentMethodModal = (paymentMethod) => {
    setPaymentMethodToDelete(paymentMethod)
    setDeletePaymentMethodModalIsOpen(true)
  }

  const deletePaymentMethod = (paymentMethod) => {
    axios.delete(`/api/payments/remove_payment_method/${paymentMethod.id}`).then((res) => {
      console.log(res)
      window.location = window.location
    })
  }


  function DeleteModalDOM({paymentMethod}) {
    return (
      <Modal
        title="Delete Payment Method"
        subtitle="Are you sure you want to delete the following payment method?"
        open={() => setDeletePaymentMethodModalIsOpen(true)}
        close={() => setDeletePaymentMethodModalIsOpen(false)}
        className="deletePaymentMethodModal"
        bodyDom={
          <div>
            { displayString(paymentMethod) }
          </div>
        }
        actionsDom={
          <div>
            <input
              type="button"
              value="Cancel"
              className="small"
              onClick={() => setDeletePaymentMethodModalIsOpen(false)}
            /> 
            <input
              type="button"
              value="Delete"
              className="small destructive"
              onClick={() => deletePaymentMethod(paymentMethod)}
            />
          </div>
        }
      />
    )
  }

  const openUpdatePaymentModal = (paymentMethod) => {
    setPaymentMethodToUpdate(paymentMethod)
    setPaymentModalOpen(true)
  }

  function displayString(paymentMethod) {
    if (paymentMethod.type === "card") {
      return `${capitalizeWord(paymentMethod.card.display_brand)} ending in ${paymentMethod.card.last4} - exp ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year - 2000}`
    } else if (paymentMethod.type === "link") {
      return `Paying with Stripe Link`
    } else {
      return `Payment method ${paymentMethod.type}`
    }
  }

  function StripePaymentMethod({ userPaymentInfo, paymentMethod }) {

    const defaultPaymentMethodId = userPaymentInfo?.customer?.invoice_settings?.default_payment_method
    const isDefault = paymentMethod.id === defaultPaymentMethodId

    return (
      <div className="stripePaymentMethod card">
        <span>
          {displayString(paymentMethod)}
        </span>
        <span>
          {
            !isDefault && (
              <button className="button small skeleton destructive" onClick={() => { openDeletePaymentMethodModal(paymentMethod) }}>delete</button>
            )
          }
        </span>
        <span>
          {
            !isDefault && (
              <button className="button small" onClick={() => { setDefaultPaymentMethod(paymentMethod) }}>set default</button>
            )
          }
          {
            isDefault && (
              <button className="button small positive" disabled={true} onClick={() => {}}>default</button>
            )
          }
        </span>
      </div>
    )
  }

  const onPaymentMethodCreated = (paymentMethod) => {
    window.location = window.location
  }

  return(
    <div className="page">
      { paymentModalOpen && <AddPaymentMethodModal setIsOpen={setPaymentModalOpen} onPaymentMethodCreated={onPaymentMethodCreated} /> }
      { deletePaymentMethodModalIsOpen && <DeleteModalDOM paymentMethod={paymentMethodToDelete} /> }
      <header className='profilePageHeader'>
        <h2>Profile</h2>
      </header>
      <div className="paymentAndAccount">
        <div className='accountAndPaymentContainer'>
          <div className="accountInfo">
            <h4>Account Info</h4>
            <LabeledField label="First Name" name="firstName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
            <LabeledField label="Last Name" name="lastName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
            <LabeledField label="Company Name" name="companyName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
            <LabeledField label="Email" name="email" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
            <input className="positive" type="button" onClick={saveInfo} value="Save" />
          </div>
          
          <PermissionsGate roles={['payments']}>
            <div className="paymentMethods">
              <h4>Saved Payment Methods</h4>

              <div>
                { userPaymentInfo.payment_methods?.map((pm) => (<StripePaymentMethod userPaymentInfo={userPaymentInfo} paymentMethod={pm} />)) }
              </div>

              <div style={{display: 'flex'}}><input type="button" onClick={openAddPaymentMethodModal} value={"Add Payment Method"} /><StripeIcon /></div>
              <div className="certificationMarks">
                <h4>ClimateHound Certification Marks</h4>
                <div className="body4">
                  This contains a zip file of all brand assets your business needs to show its carbon neutral certification with ClimateHound
                </div>
              </div>
            </div>
          </PermissionsGate>
        </div> 
        <br />
        <br />
        <PermissionsGate>
          <div>
            <UserTable />
          </div>
        </PermissionsGate>
        
        <div className='termsAndPrivacyLinks'>
          <a href="https://www.climatehound.io/serviceterms" target="_blank" rel='noreferrer'>Terms of Service</a>
          <a href="https://www.climatehound.io/privacypolicy" target="_blank" rel='noreferrer'>Privacy Policy</a> 
        </div>
      </div>
    </div>
  )
}
