import React from 'react'

import { ReactComponent as DollarSignIcon } from "./DollarSign.svg"
import { ReactComponent as ElectricLeafIcon } from "./ElectricLeaf.svg"
import { ReactComponent as PiggyBankIcon } from "./PiggyBank.svg"
import { ReactComponent as QuestionIcon } from "../../assets/Question.svg"
import classnames from 'classnames'

export default function ReductionStrategyEditorHeader(props) {
  const { rs, upfrontCost, initiativeIsCompleted, initiativeIsInProgress, setInfoModalOpen, upfront, emissions, dollars } = props
  let headerClassName = classnames('rsStats editor', {
    started: (initiativeIsCompleted(rs.status) || initiativeIsInProgress(rs.status)),
    rsPolicy: (rs.category === "POLICY")
  })
  let textSpan = classnames('', {rsPolicyText: rs.category === "POLICY"})
  return (
    <div className={headerClassName}>

        { rs.category === "REDUCTION" && (
            <header>
              <div className="body4">
                Impact - {rs.is_lifetime_impact ? "Lifetime" : "Annual" } <span className="impactIcon" onClick={() => {setInfoModalOpen(true)}}><QuestionIcon/></span>
              </div>
            </header>
          ) 
        }
      <div className="stat reductionPotential">
        <label className="micro">
          <ElectricLeafIcon />
        </label>
        <div className="body4">
          <span>GHG Impact</span>
          <span className={textSpan}>
            { rs.category === "REDUCTION" ? (
              rs.kgco2e_reduced && `${(rs.kgco2e_reduced * 0.001).toFixed(2)} MTCO2e`
            ) : emissions
            } 
          </span>
        </div>
      </div>
      <div className="stat">
        <label className="micro">
          <PiggyBankIcon />
        </label>
        <div className="body4">
          <span>Savings</span>
          <span className={textSpan}>
            { rs.category === "REDUCTION" ? (
              rs.dollars_saved && `$${parseFloat(rs.dollars_saved.toFixed(2)).toLocaleString()}`
            ) : dollars 
            }
          </span>
        </div>
      </div>
      <div className="stat">
        <label className="micro">
          <DollarSignIcon />
        </label>
        <div className="body4">
          <span>Cost</span>
          <span className={textSpan}>
            {  rs.category === "REDUCTION" ? upfrontCost : upfront }
          </span>
        </div>
      </div>
    </div>
  )
}